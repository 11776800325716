<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <el-row>
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="姓名">
          <el-input v-model="name"></el-input>
        </el-form-item>
        <el-form-item label="电话">
          <el-input v-model="phone"></el-input>
        </el-form-item>
        <el-form-item label="教学类目">
          <el-select
            v-model="teaching_categories"
            clearable
            name="categories_child"
            remote
            style="width: 150px"
          >
            <el-option
              v-for="item in listPageChild"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select
            v-model="status"
            placeholder="请选择"
            style="width: 150px"
            clearable
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="会员">
          <el-select
            clearable
            v-model="identity_type"
            placeholder="请选择"
            style="width: 150px"
          >
            <el-option label="注册教练" :value="1"> </el-option>
            <el-option label="会员教练" :value="2"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-button type="text" @click="filterShow = !filterShow">{{
            filterShow ? "收起筛选" : "更多筛选"
          }}</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <excelExport
            ref="export"
            :tableName="tableName"
            :values="values"
            :titles="titles"
            @startExport="startExport"
          >
            <el-radio v-model="extype" :label="1"
              >当前页数据，{{ DataList.length }}条</el-radio
            >
            <el-radio v-model="extype" :label="2"
              >导出当前条件的全部数据，{{ Total }}条</el-radio
            >
          </excelExport>
        </el-form-item>
        <el-form-item label="城市" v-if="filterShow">
          <el-cascader
            clearable
            v-model="cityList"
            :options="cityoptions"
            :props="cityProps"
            :placeholder="cityList[1]"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="毕业情况" v-if="filterShow">
          <el-select
            clearable
            v-model="is_graduate"
            placeholder="请选择"
            style="width: 150px"
          >
            <el-option label="已毕业" :value="true"> </el-option>
            <el-option label="在校" :value="false"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="教练身份" v-if="filterShow">
          <el-select
            clearable
            v-model="c_role"
            placeholder="请选择"
            style="width: 150px"
          >
            <el-option label="普通教练" value="comm"> </el-option>
            <el-option label="签约教练" value="sign"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="注册时间" v-if="filterShow">
          <el-date-picker
            v-model="createTime"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="更新时间" v-if="filterShow">
          <el-date-picker
            v-model="updateTime"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <!-- 列表 -->
      <el-table
        :data="DataList"
        :fit="true"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{ 'text-align': 'center' }"
        v-loading="is_loading"
      >
        <el-table-column label="教练名称" prop="real_name">
          <template v-slot="{row}">
            <div>
              {{row.real_name}}
              <span v-if="row.coach_role == 'vSign'">(特约教练)</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="电话" prop="phone">
          <template v-slot="{row}">
            <div>
              {{row.phone || ''}}
              <span v-if="row.coach_role == 'vSign'">虚拟教练</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="教学类目"
          prop="teaching_categories"
        ></el-table-column>
        <el-table-column label="性别" prop="sex">
          <template slot-scope="{ row }">
            <p v-if="row.sex == 0">未知</p>
            <p v-if="row.sex == 1">男</p>
            <p v-if="row.sex == 2">女</p>
          </template>
        </el-table-column>
        <el-table-column label="城市" prop="city"></el-table-column>
         <el-table-column
          label="会员"
        >
         <template slot-scope="{ row }">
          <div v-if="row.identity_type == 1">普通</div>
          <div v-if="row.identity_type == 2" style="color:#F78D36">会员</div>
          </template>
        </el-table-column>
        <el-table-column label="毕业" prop="status">
          <template slot-scope="{ row }">
            <div v-if="row.is_graduate === true">毕业</div>
            <div v-else-if="row.is_graduate === false">在校</div>
            <div v-else>未知</div>
          </template>
        </el-table-column>
        <el-table-column
          label="时间"
          prop="create_time"
          width="220"
        >
         <template slot-scope="{ row }">
          <div>注册:{{row.create_time}}</div>
          <div>更新:{{row.update_time}}</div>
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="status">
          <template slot-scope="{ row }">
            <el-tag type="warning" v-if="row.status == 0">信息待提交</el-tag>
            <el-tag type="info" v-if="row.status == 1">待审核</el-tag>
            <el-tag type="success" v-if="row.status == 2">审核通过</el-tag>
            <el-tag type="danger" v-if="row.status == 3">审核拒绝</el-tag>
            <el-tag type="danger" v-if="row.status == 4">已注销</el-tag>
            <el-tag type="danger" v-if="row.status == 5">已冻结</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="250px">
          <template slot-scope="{ row }">
            <div class="flex flex-wrap">
              <el-button type="primary" @click="toDetails(row)" size="small"
                >查看详情</el-button
              >
              <!-- <el-button
                type="success"
                v-if="row.status == 1"
                @click="update(row, 2)"
                size="small"
                >通过</el-button
              >
              <el-button
                type="danger"
                v-if="row.status == 1"
                @click="update(row, 3)"
                size="small"
                >拒绝</el-button
              > -->
              <el-button
                type="danger"
                v-if="row.status == 2"
                @click="frozen(row, 5)"
                size="small"
                >冻结</el-button
              >
              <el-button
                type="success"
                v-if="row.status == 5"
                @click="frozen(row, 2)"
                size="small"
                >解冻</el-button
              >
              <el-button
                v-if="row.coach_role == 'comm'"
                type="warning"
                @click="openRole(row)"
                size="small"
                >特约申请</el-button
              >
              <el-button
                v-if="row.coach_role == 'comm'"
                type="success"
                @click="openUnionDev(row)"
                size="small"
                >公会开发人</el-button
              >
              <el-button
                v-if="row.coach_role == 'vSign'"
                type="success"
                @click="openCommunity(row)"
                size="small"
                >入驻社区</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <paging-fy
        @currentPageChange="changeCurrentPage"
        :currentPage="currentPage"
        :total="Total"
      ></paging-fy>
    </el-row>

    <el-dialog title="申请特约教练" :visible.sync="roleShow" width="30%">
      <el-form>
        <el-form-item label="教练">
          {{ curCoach.real_name }}
        </el-form-item>

        <el-form-item label="申请说明" required>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="apply_remark"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="submitSignApply"
            >提交申请</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="申请成为公会开发人员" :visible.sync="devShow" width="30%">
      <el-form>
        <el-form-item label="教练">
          {{ curCoach.real_name }}
        </el-form-item>

        <el-form-item label="申请说明" required>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="apply_remark"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="submitDevApply"
            >提交申请</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="入驻社区" :visible.sync="communityShow" width="50%">
      <el-form>
        <el-form-item label="教练">
          {{ curCoach.real_name }}
        </el-form-item>

        <div v-if="is_add">
          <el-form-item label="社区">
            <mechanism-select
              ref="sel"
              :mecid.sync="mecid"
              :condition="{ is_community: true }"
            ></mechanism-select>
          </el-form-item>
          <el-form-item label="">
            <el-button type="success" @click="addCommunity">确认入驻</el-button>
          </el-form-item>
        </div>
        <el-form-item label="特约教练已入住社区">
          <el-button type="primary" size="small" v-if="is_add == false" @click="is_add = true"
            >添加</el-button
          >
        </el-form-item>
        <auto-table
          :DataList="communityList"
          :option="comm_option"
          :showPage="false"
        >
          <template #handle="{ row }">
            <div>
              <el-button type="danger" size="small" @click="deleteComm(row)"
                >解绑</el-button
              >
            </div>
          </template>
        </auto-table>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import mechanismSelect from "../../components/select/mechanismSelect.vue";
import userSelect from "../../components/select/userSelect.vue";
import excelExport from "@/components/excel/excelExport.vue";
export default {
  name: "coachList",
  components: {
    mechanismSelect,
    userSelect,
    excelExport,
  },
  data() {
    return {
      nav: { firstNav: "教练管理中心", secondNav: "教练列表" },
      filterShow: false,
      devShow:false,
      DataList: [],
      is_loading: true,
      currentPage: 1,
      Total: 0,
      status: "",
      coach_roleMap: {
        comm: "普通教练",
        sign: "特约教练",
      },
      options: [
        {
          value: 0,
          label: "信息待提交",
        },
        {
          value: 1,
          label: "待审核",
        },
        {
          value: 2,
          label: "已通过",
        },
        {
          value: 3,
          label: "已拒绝",
        },
        {
          value: 4,
          label: "已注销",
        },
        {
          value: 5,
          label: "已冻结",
        },
      ],
      identity_type: "",
      user_id: "",
      mechanism_id: "",
      price: "",
      listPageChild: [],
      teaching_categories: "",
      name: "",
      phone: "",
      tableName: "海马运动教练",
      values: [
        "real_name",
        "phone",
        "teaching_categories",
        "sex",
        "city",
        "create_time",
      ],
      titles: ["姓名", "电话", "类目", "性别", "城市", "注册时间"],
      cityoptions: [],
      cityProps: {
        checkStrictly: true,
        value: "district",
        label: "district",
        children: "childrens",
      },
      cityList: [],
      extype: 1,
      is_graduate: "",
      createTime: [],
      updateTime: [],
      roleShow: false,
      curCoach: {},
      c_role: "",
      mecid: "",
      communityList: [],
      communityShow: false,
      admin_id: "",
      comm_option: [
        { name: "社区", value: "mechanism_name" },
        { name: "入驻时间", value: "create_time" },
        { name: "操作", value: "handle", type: "custom" },
      ],
      apply_remark: "",
      adminInfo: JSON.parse(localStorage.getItem("managerInfo")),
      is_add: false,
    };
  },
  mounted() {
    this.getList();
    this.GetListPageChild();
    this.getAddrList();
  },
  methods: {
    submitSignApply() {
      if (!this.apply_remark) {
        this.$message("请填写申请理由");
        return;
      }

      let data = {
        coach_id: this.curCoach.id,
        coach_name: this.curCoach.real_name,
        remark: this.apply_remark,
        type: "coach_sign",
        submit_id: this.adminInfo.id,
        submit_name: this.adminInfo.name,
      };
      this.$post("/user/authApply/insert", data).then((res) => {
        this.roleShow = false;
        this.$alert("特约教练提交成功，审核通过后可设置教练入驻的机构");
      });
    },
    submitDevApply(){
      if (!this.apply_remark) {
        this.$message("请填写申请理由");
        return;
      }

      let data = {
        coach_id: this.curCoach.id,
        coach_name: this.curCoach.real_name,
        remark: this.apply_remark,
        type: "coach_dev",
        submit_id: this.adminInfo.id,
        submit_name: this.adminInfo.name,
      };
      this.$post("/user/authApply/insert", data).then((res) => {
        this.devShow = false;
        this.$alert("申请提交成功，请等待审核通过。");
      });
    },
    getAddrList() {
      let list = JSON.parse(localStorage.getItem("addrList"));
      let area = JSON.parse(localStorage.getItem("cur_agentAreas"));
      let cooperator = JSON.parse(localStorage.getItem("cooperator"));
      if (cooperator && area) {
        let province;
        for (let i = 0; i < list.length; i++) {
          if (list[i].district === area.province) {
            province = list[i];
            break;
          }
        }

        console.log(province);
        if (cooperator.agent_level == "province") {
          this.cityoptions = province.childrens;
        }
        if (cooperator.agent_level == "city") {
          province.childrens.forEach((item) => {
            if (item.district == area.city) {
              this.cityoptions = item.childrens;
            }
          });
        }
      } else {
        this.cityoptions = list;
      }
    },
    openRole(v) {
      this.curCoach = v;
      this.roleShow = true;
    },
    openUnionDev(v) {
      this.curCoach = v;
      this.devShow = true;
    },

    openCommunity(v) {
      this.curCoach = v;
      this.getCoachCommunity();
      this.communityShow = true;
    },
    //删除关系
    deleteComm(v) {
      this.$confirm("确定解除社区教练入驻关系").then((res) => {
        this.$get("user/coachCommunity/delete", {
          id: v.id,
        }).then((res) => {
          this.getCoachCommunity();
        });
      });
    },
    //添加教练入驻社区
    addCommunity() {
      this.$post("user/coachCommunity/insert", {
        coach_id: this.curCoach.id,
        community_id: this.mecid,
      }).then((res) => {
        this.$refs.sel.clear();
        this.getCoachCommunity();
      });
    },
    //教练已入驻社区
    getCoachCommunity() {
      this.communityList = [];
      this.$get("user/coachCommunity/query", {
        coach_id: this.curCoach.id,
      }).then((res) => {
        this.communityList = res.data.data;
      });
    },

    // 导出表格
    startExport() {
      if (this.extype === 1) {
        let DataList = this.DataList;
        DataList.forEach((item) => {
          item.sex = item.sex === 1 ? "男" : "女";
        });
        this.$refs.export.importExcel(DataList);
      } else {
        this.exportInfo();
      }
    },
    exportInfo() {
      let params = {
        status: this.status,
        real_name: this.name || null,
        phone: this.phone || null,
        identity_type: this.identity_type || null,
      };
      if (this.teaching_categories) {
        params.teaching_categories = this.teaching_categories;
      }

      let cooperator = JSON.parse(localStorage.getItem("cooperator"));
      if (this.cityList.length > 0) {
        let len = this.cityList.length;
        if (cooperator) {
          switch (cooperator.agent_level) {
            case "province":
              params.city = this.cityList[len - 1];
              break;
            case "city":
              params.district = this.cityList[len - 1];
              break;
            default:
              break;
          }
        } else {
          let list = ["province", "city", "district"];
          params[list[len - 1]] = this.cityList[len - 1];
        }
      }

      this.$axios({
        url: "/user/coach/query",
        params,
      }).then((res) => {
        let DataList = res.data.data;
        DataList.forEach((item) => {
          item.sex = item.sex === 1 ? "男" : "女";
        });
        this.$refs.export.importExcel(DataList);
      });
    },
    frozen(e, v) {
      console.log(e, v);
      this.$post("user/coach/freeze", {
        id: e.id,
        status: v,
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message({ message: "成功" });
          this.getList();
        } else {
          this.$message({ message: res.data.message });
        }
      });
    },
    GetListPageChild() {
      let url = this.$axios({
        url: "/user/mechanismCategory/queryListPageChild",
        params: {
          type: 2,
          status: 2,
          source: "课程",
        },
      }).then((res) => {
        this.listPageChild = res.data.data;
      });
    },
    toDetails(row) {
      this.$router.push({ name: "coachDetails", query: { id: row.id } });
    },
    //更新状态
    update(row, status) {
      let url = "/user/coach/update";
      let data = {
        id: row.id,
        status,
      };
      this.$axios({
        url,
        data,
        method: "post",
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message({ message: "成功" });
          this.getList();
        } else {
          this.$message({ message: res.data.message });
        }
      });
    },
    //列表
    getList() {
      this.is_loading = true;
      let url = "/user/coach/queryManagerListPage";
      let params = {
        pageSize: 10,
        currentPage: this.currentPage,
        status: this.status,
        real_name: this.name || null,
        phone: this.phone || null,
        identity_type: this.identity_type || null,
        coach_role: this.c_role || null,
      };
      if (this.teaching_categories) {
        params.teaching_categories = this.teaching_categories;
      }
      if (typeof this.is_graduate == "boolean") {
        params.is_graduate = this.is_graduate;
      }
      if (this.createTime !== null && this.createTime.length > 0) {
        params.start_time = this.createTime[0] + " 00:00:00";
        params.end_time = this.createTime[1] + " 23:59:59";
      }
      if (this.updateTime !== null && this.updateTime.length > 0) {
        params.u_start_time = this.updateTime[0] + " 00:00:00";
        params.u_end_time = this.updateTime[1] + " 23:59:59";
      }

      let cooperator = JSON.parse(localStorage.getItem("cooperator"));
      if (this.cityList.length > 0) {
        let len = this.cityList.length;
        if (cooperator) {
          switch (cooperator.agent_level) {
            case "province":
              params.city = this.cityList[len - 1];
              break;
            case "city":
              params.district = this.cityList[len - 1];
              break;
            default:
              break;
          }
        } else {
          let list = ["province", "city", "district"];
          params[list[len - 1]] = this.cityList[len - 1];
        }
      }
      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
          this.DataList = res.data.data.rows;
          this.Total = res.data.data.total;
          this.is_loading = false;
        });
    },
    search() {
      this.currentPage = 1;
      this.getList();
    },
    changeCurrentPage(v) {
      this.currentPage = v;
      this.getList();
    },
  },
};
</script>

<style lang="less">
.expand-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  font-size: 20px;
  line-height: 1.5;

  .el-form-item {
    width: 40%;
  }
}
</style>
